<template>
    <div v-if="
          getUser.role.slug === 'super-administrateur' ||
          getUser.role.slug === 'responsable-relation-client' ||
          getUser.role.slug === 'comptable' ||
          getUser.role.slug === 'assistante-administrative' ||
          getUser.role.slug === 'charge-daffaires'
        ">
        <b-card>
            <b-tabs pills>
                <b-tab active>
                    <template #title>
                        <feather-icon icon="ShoppingBagIcon" class="text-white mr-1" size="20" />
                        Salaire sans CNSS
                    </template>
                    <hr />
                    <b-card no-body>
                        <b-card-header class="pb-50">
                            <h5>Filtrages</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Client</label>
                                            <v-select v-model="payloadFilter.user_id" :options="customerOptions" class="invoice-filter-select" label="name" :reduce="(customer) => customer.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isCustomersWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Employés</label>
                                            <v-select v-model="payloadFilter.employee_id" :options="employeeOptions"
                                                class="invoice-filter-select" label="name" :reduce="(employee) => employee.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isEmployeesWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                                                    class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun employée ne correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>
    
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Mois</label>
                                            <v-select v-model="payloadFilter.month_salary" :options="monthOptions" label="text" class="invoice-filter-select" :reduce="(item) => item.value" />
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Année</label>
                                            <v-select v-model="payloadFilter.year" :options="yearOptions" class="invoice-filter-select w-100 mr-1" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Statut</label>
                                            <v-select v-model="payloadFilter.status" :options="paymentStatusOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
    
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="advance">Avance</label>
                                            <v-select v-model="payloadFilter.employee_received_salary_advance" :options="advanceOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                        <b-col v-if="
                                            getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'responsable-relation-client'
                                            " cols="12" sm="6" md="3" lg="3">
                                            <label for="">Chargé d'affaire</label>
                                            <b-form-select v-if="
                                                businessManagerOptions.length === 1 &&
                                                businessManagerOptions[0].disabled
                                                " id="charge_daffaire_id" :options="businessManagerOptions" />
                                            <v-select v-else id="role" v-model="payloadFilter.ca_id" :reduce="(businessManager) => businessManager.id" label="full_name" :options="businessManagerOptions">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    Chargement...
                                                </span>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Salaire bloqué</label>
                                            <v-select v-model="payloadFilter.auto_send" :options="autoSendOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="1" lg="1" class="mt-2">
                                            <b-button variant="primary" @click="applyFilterDirectSalaryPaymentWithoutCnssAction(0)">
                                                <div v-if="isFilterDirectSalaryPayment">
                                                    <span> Chargement ... </span>
                                                    <b-spinner small />
                                                </div>
                                                <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
    
                    <b-card title="Historique des salaires">
                        <b-row>
                            <!-- success -->
    
                            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-2">
                                <b-button variant="primary" @click="reLoadDataPaymentHistoryDirectOrdersAction()">
                                    <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                        <span> Chargement ... </span>
                                        <b-spinner small />
                                    </div>
                                    <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                                </b-button>
                            </b-col>
                        </b-row>
    
                        <vue-good-table :columns="columns" :rows="rowsWithoutCnss" :is-loading="isPaymentHistoryLoading">
                            <template slot="emptystate">
                                <div class="text-center">
                                    <span> {{ isPaymentHistoryLoading ? "" : "Liste vide" }} </span>
                                </div>
                            </template>
    
                            <template slot="loadingContent">
                                <div class="text-center text-info">
                                    <b-spinner class="align-middle" /> &nbsp;
                                    <span class="align-middle">Chargement... </span>
                                </div>
                            </template>
    
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'customer_full_name'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.customer_full_name
                      }}</span>
                                    <span class="d-block text-nowrap text-warning font-small-5" v-if="props.row.is_company == true && props.row.company_ifu != 'null'">IFU:
                                        <span class="d-block text-nowrap text-warning font-small-5">{{
                       props.row.company_ifu 
                      }}</span>
                                    </span>
                                </div>
    
                                <div v-else-if="props.column.field === 'month_salary'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.month_salary
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'salary_paid_date'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.salary_paid_date == null ? "---------" : moment(props.row.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'year'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.year
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'total_amount_to_be_paid'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{ props.row.total_amount_to_be_paid }} FCFA</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'ylomi_direct_fees'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{ props.row.total_ylomi_direct_fees}} FCFA</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'total_salary_advance_amount'" class="text-nowrap text-center align-middle">
                                    <span v-if="props.row.total_salary_advance_amount" class="d-block text-nowrap text-info font-small-5">{{ props.row.total_salary_advance_amount}} FCFA</span>
                                    <span v-else class="d-block text-nowrap text-info font-small-5">0 FCFA</span>
    
                                </div>
                                <div v-else-if="props.column.field === 'montantApayer'" class="text-nowrap text-center align-middle">
                                    <span v-if="montantApayer" class="d-block text-nowrap text-info font-small-5">{{ montantApayer(props.row.total_amount_to_be_paid, props.row.total_salary_advance_amount )}} FCFA</span>
    
                                </div>
    
                                <!-- Column: Status -->
                                <div v-else-if="props.column.field === 'setStatus'" class="align-middle text-center font-small-5">
                                    <b-badge pill :variant="`light-${resolveOrderStatusVariant(props.row.status)}`" class="text-capitalize font-medium-1">
                                        {{ props.row.setStatus }}
                                    </b-badge>
                                </div>
    
                                <!--  ACTION -->
                                <div v-else-if="props.column.field === 'action'">
                                    <span>
                                        <feather-icon v-b-modal.modal-action-without-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosCumul(props.row)" />
                                    </span>
                                </div>
                                <!--  <div
                      
                      class="align-middle text-center"
                    >
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="20"
                              class="align-middle mr-25 text-info"
                            />
                          </template>
                          <b-dropdown-item
                            v-b-modal.modal-details-salary-without-cnss
                            :id="`details${props.row.id}`"
                            @click="showSalaireWithoutCnssDetails(props.row, 0)"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              class="cursor-pointer text-info mr-1"
                              size="25"
                            />
                            <span>Voir détails</span>
                          </b-dropdown-item>
    
                          <b-dropdown-item
                           v-if="
                                getUser.role.slug === 'super-administrateur' && props.row.status == 0 
                              "
                            :id="`confirm${props.row.id}`"
                            @click="applyConfirmSalaryCumulWithoutCnssAction(props.row, 0)"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="cursor-pointer text-success mr-1"
                              size="25"
                            />
                            <span>Confirmer le paiement </span>
                          </b-dropdown-item>
    
                        <b-dropdown-item
                          v-b-modal.modal-generate-link
                           v-if="
                              getUser.role.slug === 'responsable-relation-client' && props.row.status == 0 ||
                              getUser.role.slug === 'super-administrateur' && props.row.status == 0
                            "
                            :id="`link${props.row.id}`"
                            @click="clikedWithoutCnss(props.row, 0)"
                          >
                            <feather-icon
                              icon="LinkIcon"
                              color="success"
                              class="cursor-pointer text-info mr-1"
                              size="25"
                            />
                            <span>Générer lien de payement au client</span>
                          </b-dropdown-item>
                          
                          <b-dropdown-item
                           v-if="
                                getUser.role.slug === 'super-administrateur' && props.row.status == 0  
                              "
                            :id="`cancel${props.row.id}`"
                            class="cursor-pointer"
                            :target="`cancel${props.row.id}`"
                            @click="applyCancelCumulPaymentWithoutCnssAction(props.row, 0)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              color="red"
                              class="cursor-pointer mr-1"
                              size="25"
                            />
                            <span>Annuler le paiement</span>
                          </b-dropdown-item>
    
                          
                           <b-dropdown-item
                            :id="`avance${props.row.id}`"
                            v-if="props.row.status == 0"
                            @click="showAdvancePayementMonthModal(props.row)"
                          >
                            <feather-icon
                              icon="CreditCardIcon"
                              class="cursor-pointer text-info mr-1"
                              size="25"
                            />
                            <span>Avance sur salaire</span>
                          </b-dropdown-item>
                         
                        </b-dropdown>
                      </span>
                    </div> -->
                            </template>
    
                        </vue-good-table>
                        <!-- MODAL ACTIONS SANS CNSS -->
                        <b-modal id="modal-action-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                            <b-list-group>
                                <b-list-group-item button v-b-modal.modal-details-salary-without-cnss @click="showSalaireWithoutCnssDetails(salaryInfos, 0)">
                                    <feather-icon icon="EyeIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Voir détails</span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable')  && salaryInfos.status == 0 " @click="applyConfirmSalaryCumulWithoutCnssAction(salaryInfos, 0)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le paiement </span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-b-modal.modal-generate-link v-if="getUser.role.slug === 'responsable-relation-client' && salaryInfos.status == 0 || getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0 || getUser.role.slug === 'comptable' && salaryInfos.status == 0 || getUser.role.slug === 'assistante-administrative' && salaryInfos.status == 0" @click="clikedWithoutCnss(salaryInfos, 0)">
                                    <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Générer lien de payement au client</span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salaryInfos.status == 0 " @click="applyCancelCumulPaymentWithoutCnssAction(salaryInfos, 0)">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                    <span class="align-middle ml-50">Annuler le paiement</span>
                                </b-list-group-item>
                            </b-list-group>
    
                        </b-modal>
    
                        <!-- DETAILS D'UN PAIEMENT DE SALAIRE SANS CNSSS-->
                        <b-modal id="modal-details-salary-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER SANS CNSS" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="salaryDetails" responsive :fields="tableColumns2" primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryWithoutCnssStatusVariant(
                          data.item.status
                        )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
                                <!-- Salaire -->
                                <template #cell(assurance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.assurance_amount }} FCFA
                                    </span>
                                </template>
    
                                <!-- Service  -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.service_name }}
                                    </span>
                                </template>
    
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>
    
                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{clickedDetailCustomerName }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ clickedDetailIfu }}
                                    </span>
                                </template>
    
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="i" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee_full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount   }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary( data.item.employee_salary_amount, data.item.salary_advance_amount) }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount ) }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                        data.item.salary_paid_date == null ? "---------" : moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </template>
    
                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data">
    
                                    <feather-icon  v-b-modal.modal-action-singular-without-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosSingulier(data.item)" />
    
                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS SANS CNSS -->
                            <b-modal id="modal-action-singular-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="(getUser.role.slug === 'super-administrateur' ||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" v-b-modal.modal-details-salary-without-cnss @click="confirmSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Confirmer le Paiement</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable')&& salarySingularInfos.status == 0" @click="cancelSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-b-modal.modal-generate-link v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"  @click="clikedSigular(salarySingularInfos)">
                                        <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Générer lien de payement au client</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" 
                                        @click="showAdvanceSingularPayementMonthModalWithoutCnss(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="blockSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                        <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                        <span v-else>Débloqué le salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable' "  @click="employeeWallet(salarySingularInfos, 0)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span >Porteffeuille employé</span>
                                    </b-list-group-item>
                                </b-list-group>
    
                            </b-modal>
                        </b-modal>
                        <div class="d-flex justify-content-between mt-2 flex-wrap">
                            <b-button :disabled="metaDataWithoutCnss.prev_page_url == null" variant="outline-secondary" @click="
                      applyLoadMoreDirectOrderPaymentHistoryAction(metaDataWithoutCnss.prev_page_url)
                    ">
                                <span class="text-white text-nowrap font-medium-2">Page précédente</span>
                            </b-button>
    
                            <div>
                                <span class="text-white font-medium-2">{{ metaDataWithoutCnss.current_page }} sur {{ metaDataWithoutCnss.last_page }} pages</span>
                            </div>
                            <div>
                                <span class="text-white font-medium-2">Total :{{ totalWithoutCnss }}</span>
                            </div>
                            <b-button :disabled="metaDataWithoutCnss.next_page_url == null" variant="outline-secondary" @click="
                      applyLoadMoreDirectOrderPaymentHistoryAction(metaDataWithoutCnss.next_page_url)
                    ">
                                <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
                            </b-button>
                        </div>
                        <!-- MODAL DE DETAIL DE L'HISTORIQUE DE PAYEMENT DU CLIENT -->
                    </b-card>
                </b-tab>
    
                <b-tab>
                    <template #title>
                        <feather-icon icon="ShoppingBagIcon" class="text-white mr-1" size="20" />
                        Salaire avec CNSS
                    </template>
                    <hr />
                    <b-card no-body>
                        <b-card-header class="pb-50">
                            <h5>Filtrages</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Client</label>
                                            <v-select v-model="payloadFilter.user_id" :options="customerOptions" class="invoice-filter-select" label="name" :reduce="(customer) => customer.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isCustomersWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Employés</label>
                                            <v-select v-model="payloadFilter.employee_id" :options="employeeOptions"
                                                class="invoice-filter-select" label="name" :reduce="(employee) => employee.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isEmployeesWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                                                    class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun employée ne correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>
    
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Mois</label>
                                            <v-select v-model="payloadFilter.month_salary" :options="monthOptions" label="text" class="invoice-filter-select" :reduce="(item) => item.value" />
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Année</label>
                                            <v-select v-model="payloadFilter.year" :options="yearOptions" class="invoice-filter-select w-100 mr-1" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Statut</label>
                                            <v-select v-model="payloadFilter.status" :options="paymentStatusOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
    
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="advance">Avance</label>
                                            <v-select v-model="payloadFilter.employee_received_salary_advance" :options="advanceOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                        <b-col v-if="
                                            getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'responsable-relation-client'
                                            " cols="12" sm="6" md="3" lg="3">
                                            <label for="">Chargé d'affaire</label>
                                            <b-form-select v-if="
                                                businessManagerOptions.length === 1 &&
                                                businessManagerOptions[0].disabled
                                                " id="charge_daffaire_id" :options="businessManagerOptions" />
                                            <v-select v-else id="role" v-model="payloadFilter.ca_id" :reduce="(businessManager) => businessManager.id" label="full_name" :options="businessManagerOptions">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    Chargement...
                                                </span>
                                            </v-select>
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Salaire bloqué</label>
                                            <v-select v-model="payloadFilter.auto_send" :options="autoSendOptions" class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                        <b-col cols="12" sm="6" md="1" lg="1" class="mt-2">
                                            <b-button  variant="primary" @click="applyFilterBusinessSalaryPaymentAction(1)">
                                                <div v-if="isFilterDirectSalaryPayment">
                                                    <span> Chargement ... </span>
                                                    <b-spinner small />
                                                </div>
                                                <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" md="10" lg="10" sm="6">
                                    <label for="service">Date de Transaction</label>
                                    <b-form-group>
                                        <flat-pickr v-model="rangeDate" class="form-control" placeholder="Sélectionner une intervalle de date" :config="config" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                                    <b-button
                                        :disabled="rangeDateFilter || rangeDate == null"
                                        variant="primary"
                                        @click="eraseFilterCnss()"
                                        >
                                        {{ isFilterCnss ? "Chargement..." : "Effacer" }}
                                     </b-button>
                                    <!-- <b-button  variant="primary" @click="applyFilterCnssAction()">
                                        <div v-if="isFilterCnss">
                                            <span> Chargement ... </span>
                                            <b-spinner small />
                                        </div>
                                        <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                    </b-button> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
    
                    <b-card title="Historique des salaires">
                        <b-row>
                            <!-- success -->
    
                            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-2">
                                <b-button variant="primary" @click="reLoadDataPaymentHistoryDirectOrdersAction()">
                                    <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                        <span> Chargement ... </span>
                                        <b-spinner small />
                                    </div>
                                    <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                                </b-button>
                            </b-col>
                        </b-row>
    
                        <vue-good-table :columns="columns" :rows="rowsWithCnss" :is-loading="isPaymentHistoryLoading">
                            <template slot="emptystate">
                                <div class="text-center">
                                    <span> {{ isPaymentHistoryLoading ? "" : "Liste vide" }} </span>
                                </div>
                            </template>
    
                            <template slot="loadingContent">
                                <div class="text-center text-info">
                                    <b-spinner class="align-middle" /> &nbsp;
                                    <span class="align-middle">Chargement... </span>
                                </div>
                            </template>
    
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'customer_full_name'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.customer_full_name
                      }}</span>
    
                                    <span class="d-block text-nowrap text-warning font-small-5" v-if="props.row.is_company == true && props.row.company_ifu != 'null'">IFU:
                                        <span class="d-block text-nowrap text-warning font-small-5">{{
                       props.row.company_ifu 
                      }}</span>
    
                                    </span>
                                </div>
    
                                <div v-else-if="props.column.field === 'month_salary'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.month_salary
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'salary_paid_date'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.salary_paid_date == null ? "---------" : moment(props.row.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'year'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{
                        props.row.year
                      }}</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'total_amount_to_be_paid'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{ props.row.total_amount_to_be_paid }} FCFA</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'ylomi_direct_fees'" class="text-nowrap text-center align-middle">
                                    <span class="d-block text-nowrap text-info font-small-5">{{ props.row.total_ylomi_direct_fees }} FCFA</span>
                                </div>
    
                                <div v-else-if="props.column.field === 'total_salary_advance_amount'" class="text-nowrap text-center align-middle">
                                    <span v-if="props.row.total_salary_advance_amount" class="d-block text-nowrap text-info font-small-5">{{ props.row.total_salary_advance_amount}} FCFA</span>
                                    <span v-else class="d-block text-nowrap text-info font-small-5">0 FCFA</span>
    
                                </div>
                                <div v-else-if="props.column.field === 'montantApayer'" class="text-nowrap text-center align-middle">
                                    <span v-if="montantApayer" class="d-block text-nowrap text-info font-small-5">{{ montantApayer(props.row.total_amount_to_be_paid, props.row.total_salary_advance_amount )}} FCFA</span>
    
                                </div>
    
                                <!-- Column: Status -->
                                <div v-else-if="props.column.field === 'setStatus'" class="align-middle text-center font-small-5">
                                    <b-badge pill :variant="`light-${resolveOrderStatusVariant(props.row.status)}`" class="text-capitalize font-medium-1">
                                        {{ props.row.setStatus }}
                                    </b-badge>
                                </div>
    
                                <!--  ACTION -->
                                <div v-else-if="props.column.field === 'action'">
                                    <span>
                                        <feather-icon v-b-modal.modal-action-with-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosCumul(props.row)" />
                                    </span>
                                </div>
    
                            </template>
    
                        </vue-good-table>
                        <!-- MODAL ACTIONS SANS CNSS -->
                        <b-modal id="modal-action-with-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                            <b-list-group>
                                <b-list-group-item button v-b-modal.modal-details-salary @click="showSalaireDetails(salaryInfos, 1)">
                                    <feather-icon icon="EyeIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Voir détails</span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salaryInfos.status == 0 " @click="applyConfirmSalaryCumulAction(salaryInfos, 1)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le paiement </span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-b-modal.modal-generate-link v-if="getUser.role.slug === 'responsable-relation-client' && salaryInfos.status == 0 || getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0" @click="clikedWithCnss(salaryInfos, 1)">
                                    <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Générer lien de payement au client</span>
                                </b-list-group-item>
    
                                <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salaryInfos.status == 0 " @click="applyCancelCumulPaymentAction(salaryInfos, 1)">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                    <span class="align-middle ml-50">Annuler le paiement</span>
                                </b-list-group-item>
                            </b-list-group>
    
                        </b-modal>
    
                        <!-- DETAILS D'UN PAIEMENT DE SALAIRE AVEC CNSS -->
                        <b-modal id="modal-details-salary" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER AVEC CNSS" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="salaryDetails" responsive :fields="tableColumns1" primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                          data.item.status
                        )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
    
                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.service_name }}
                                    </span>
                                </template>
    
                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{clickedDetailCustomerName }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ clickedDetailIfu }}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="i" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee_full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount   }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary( data.item.employee_salary_amount, data.item.salary_advance_amount) }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount ) }} FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>
    
                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                       data.item.salary_paid_date == null ? "---------" :  moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </template>
    
                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data">
    
                                    <feather-icon  v-b-modal.modal-action-singular-with-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosSingulier(data.item)" />
    
                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS AVEC CNSS -->
                            <b-modal id="modal-action-singular-with-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="(getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable')&& salarySingularInfos.status == 0" v-b-modal.modal-details-salary-without-cnss @click="confirmSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Confirmer le Paiement</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="cancelSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-b-modal.modal-generate-link v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'responsable-relation-client') && salarySingularInfos.status == 0"  @click="clikedSigular(salarySingularInfos)">
                                        <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Générer lien de payement au client</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" 
                                    @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="blockSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                        <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                        <span v-else>Débloqué le salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable' " @click="employeeWallet(salarySingularInfos, 1)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span >portefeuille employé</span>
                                    </b-list-group-item>
                                </b-list-group>
                                
                                
                            </b-modal>
    
                        </b-modal>
    
                        <!--MODAL FILTRE DES DETAILS D'UN PAIEMENT DE SALAIRE AVEC CNSS -->
                        <b-modal id="modal-filtre-details-salary" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER AVEC CNSS" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreSalaryDetails" responsive :fields="tableColumns1" primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                          data.item.status
                        )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
    
                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.service_name }}
                                    </span>
                                </template>
    
                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{data.item.customer_full_name }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ data.item.company_ifu !== "null"? data.item.company_ifu : ""}}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="i" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee_full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount   }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary( data.item.employee_salary_amount, data.item.salary_advance_amount) }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount ) }} FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>
    
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>
    
                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                        data.item.salary_paid_date == null ? "---------" : moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </template>
    
                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data">
    
                                    <feather-icon  v-b-modal.modal-action-singular-with-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosSingulier(data.item)" />
    
                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS AVEC CNSS -->
                            <b-modal id="modal-action-singular-with-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"  @click="confirmSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Confirmer le Paiement</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="cancelSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-b-modal.modal-generate-link v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'responsable-relation-client'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="clikedSigular(salarySingularInfos)">
                                        <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Générer lien de payement au client</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" 
                                    @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable')&& salarySingularInfos.status == 0" @click="blockSingularSalaryPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                        <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                        <span v-else>Débloqué le salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable' " @click="employeeWallet(salarySingularInfos, 1)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span >portefeuille employé</span>
                                    </b-list-group-item>
                                </b-list-group>
    
                            </b-modal>
    
                        </b-modal>
    
                        <!--MODAL FILTRE CNSS -->
                        <b-modal id="modal-filtre-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Filtre Cnss" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreCnss" responsive :fields="tableColumnsCnss" primary-key="id" :busy="isFiltreCnssLoading" show-empty empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                          data.item.status
                        )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
    
                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_service_name }}
                                    </span>
                                </template>
    
                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{data.item.customer_full_name }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ data.item.company_ifu !== "null"? data.item.company_ifu : ""}}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="i" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee_full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount   }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary( data.item.employee_salary_amount, data.item.salary_advance_amount) }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount ) }} FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>
    
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>
    
                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                        data.item.salary_paid_date == null ? "---------" : moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </template>
    
                            </b-table>
    
                            <br />
                            <hr />
                            <div>
                                <h4 class="mb-2 text-center">TOTAL CNSS</h4>
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part patronnal</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info"> {{ totalCnssCustomer}} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part employé</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalCnssEmployee}} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">{{ totalVps}} Total VPS</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalIts}} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total ITS</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalIts}} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <hr />
                                <div class="justify-content-center">
                                    <b-row>
                                        <b-col>
                                            <span class="text-md  font-weight-bold">Total CNSS</span>
                                        </b-col>
                                        <b-col>
                                            <span class="text-info">{{ totalCnss}} FCFA</span>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-modal>
    
                        <!--MODAL FILTRE DES DETAILS D'UN PAIEMENT DE SALAIRE SANS CNSS -->
                        <b-modal id="modal-filtre-details-salary-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER SANS CNSS" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreSalaryDetailsWithoutCnss" responsive :fields="tableColumns2" primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                          data.item.status
                        )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(assurance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.assurance_amount }} FCFA
                                    </span>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
    
                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.service_name }}
                                    </span>
                                </template>
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>
    
                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{data.item.customer_full_name }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ data.item.company_ifu !== "null"? data.item.company_ifu : "" }}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="i" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee_full_name }}
                                        </span>
                                    </b-media>
                                </template>
    
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount   }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary( data.item.employee_salary_amount, data.item.salary_advance_amount) }} FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount ) }} FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>
    
                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                        data.item.salary_paid_date == null ? "---------" : moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                      }}</span>
                                </template>
                                <!-- Column: Actions -->
    
                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data" >
    
                                    <feather-icon  v-b-modal.modal-action-singular-without-cnss icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfosSingulier(data.item)" />
    
                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS SANS CNSS -->
                            <b-modal id="modal-action-singular-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="(getUser.role.slug === 'super-administrateur'||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"  @click="confirmSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Confirmer le Paiement</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="cancelSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-b-modal.modal-generate-link v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'responsable-relation-client'||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" @click="clikedSigular(salarySingularInfos)">
                                        <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                        <span class="align-middle ml-50">Générer lien de payement au client</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client'||  getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0" 
                                    @click="showAdvanceSingularPayementMonthModalWithoutCnss(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>
    
                                    <b-list-group-item button v-if="(getUser.role.slug === 'super-administrateur'|| getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable')&& salarySingularInfos.status == 0 " @click="blockSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                        <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                        <span v-else>Débloqué le salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' ||
                                        getUser.role.slug === 'charge-daffaires'||
                                        getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'" @click="employeeWallet(salarySingularInfos, 0)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span >portefeuille employé</span>
                                    </b-list-group-item>
                                </b-list-group>
    
                            </b-modal>
                        </b-modal>
    
                        <div class="d-flex justify-content-between mt-2 flex-wrap">
                            <b-button :disabled="metaData.prev_page_url == null" variant="outline-secondary" @click="
                      applyLoadMoreDirectOrderPaymentHistoryAction(metaData.prev_page_url)
                    ">
                                <span class="text-white text-nowrap font-medium-2">Page précédente</span>
                            </b-button>
    
                            <div>
                                <span class="text-white font-medium-2">{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
                            </div>
                            <div>
                                <span class="text-white font-medium-2">Total :{{ total }}</span>
                            </div>
                            <b-button :disabled="metaData.next_page_url == null" variant="outline-secondary" @click="
                      applyLoadMoreDirectOrderPaymentHistoryAction(metaData.next_page_url)
                    ">
                                <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
                            </b-button>
                        </div>
                        <!-- MODAL DE DETAIL DE L'HISTORIQUE DE PAYEMENT DU CLIENT -->
                    </b-card>
                </b-tab>
    
            </b-tabs>
        </b-card>
        <!-- Modal D'avance Mensuel avec CNSS-->
        <b-modal id="modal-payment-advance-month" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Avance sur Paiement Mensuel">
            <validation-observer #default="{}" ref="advancePaymentMonth">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModal" @submit.prevent="applyAdvancePaymentAction(1)">
                    <b-form-group label="Montant" label-for="input">
                        <validation-provider #default="{ errors }" name="montant" rules="required">
                            <b-form-input id="montant" v-model="payload.salary_advance_amount" type="number" :state="errors.length > 0 ? false : null" placeholder="Montant de l'avance" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
    
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="isAdvancePaymentLoading">
                            <div v-if="isAdvancePaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Soumettre</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary" @click="hideModalPaymentAdvanceMonthForm()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
    
        <!-- Modal D'avance Mensuel sans CNSS--->
        <b-modal id="modal-payment-advance-month-without-cnss" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Avance sur Paiement Mensuel">
            <validation-observer #default="{}" ref="advancePaymentMonth">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModal" @submit.prevent="applyAdvancePaymentAction(0)">
                    <b-form-group label="Montant" label-for="input">
                        <validation-provider #default="{ errors }" name="montant" rules="required">
                            <b-form-input id="montant" v-model="payload.salary_advance_amount" type="number" :state="errors.length > 0 ? false : null" placeholder="Montant de l'avance" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
    
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="isAdvancePaymentLoading">
                            <div v-if="isAdvancePaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Soumettre</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary" @click="hideModalPaymentAdvanceMonthFormWithoutCnss()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <!-- modal de téléchargement du fichier de payement -->
        <b-modal id="modal-new-payement-operation" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Téléchargement du fichier de paiement des employés.">
            <validation-observer #default="{}" ref="downloadFile">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalPaymentFileModal" @submit.prevent="applyEmployeePayementFile">
                    <!-- Email -->
    
                    <validation-provider #default="{ errors }" name="is_in_ylomi_program" rules="required">
                        <b-form-group label="Participe au programme YLOMI ?" label-for="is_in_ylomi_program" :state="errors.length > 0 ? false : null">
                            <v-select id="is_in_ylomi_program" v-model="payement.is_in_ylomi_program" :reduce="(item) => item.value" label="name" :options="[
                      { name: 'Oui', value: 1 },
                      { name: 'Non', value: 0 },
                    ]" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
    
                    <validation-provider #default="{ errors }" name="month" rules="required">
                        <b-form-group label="Mois de la prestation à payer" label-for="month" :state="errors.length > 0 ? false : null">
                            <v-select v-model="payement.month" :options="monthOptions" label="text" :reduce="(item) => item.value" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
    
                    <validation-provider #default="{ errors }" name="mobile_money_account" rules="required">
                        <b-form-group label="Compte MOMO" label-for="mobile_money_account" :state="errors.length > 0 ? false : null">
                            <v-select id="mobile_money_account" v-model="payement.mobile_money_account" :reduce="(item) => item.value" label="name" :options="[
                      { name: 'MTN MOBILE MONEY', value: 'MTN MOMO' },
                      { name: 'MOOV MONEY', value: 'FLOOZ' },
                    ]" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
    
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button :disabled="isPaymentLoading" variant="primary" class="mr-2" type="submit">
                            <div v-if="isPaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Confirmer</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary"> Annuler </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
    
        <!-- Modal Générer lien de paiment-->
        <b-modal id="modal-generate-link" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Générer lien de paiement" size="xl" stacking>
            <b-table ref="refInvoiceListTable" :items="generate" responsive :fields="tableColumnsLink" primary-key="id" show-empty empty-text="Liste vide" class="position-relative">
                <template #table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle" /> &nbsp;
                        <strong>Chargement...</strong>
                    </div>
                </template>
                <!-- Lien -->
                <template #cell(link)="data">
                    <span> {{ data.item.paymentLink }} </span>
                </template>
    
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <div class="text-nowrap">
                        <b-dropdown variant="link" toggle-class="p-0" no-caret>
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="25" class="align-middle text-center text-info" />
                            </template>
    
                            <b-dropdown-item :id="`deploy${data.item.id}`" class="cursor-pointer itemActions" @click="copyPaymentLink()">
                                <feather-icon icon="NavigationIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Copier</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
    
                </template>
            </b-table>
        </b-modal>
    </div>
    <div v-else>
        <not-authorized />
    </div>
    </template>
    
    <script>
    import {
        BAvatar,
        BBadge,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
        BDropdown,
        BDropdownItem,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BMedia,
        BPagination,
        BRow,
        BSpinner,
        BTable,
        BTooltip,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem
    } from "bootstrap-vue";
    import {
        VueGoodTable
    } from "vue-good-table";
    import {
        avatarText
    } from "@core/utils/filter";
    import vSelect from "vue-select";
    import {
        mapActions,
        mapGetters
    } from "vuex";
    import {
        ValidationObserver,
        ValidationProvider
    } from "vee-validate";
    import {
        required
    } from "@validations";
    import flatPickr from "vue-flatpickr-component";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import axios from "axios";
    import store from "@/store/index";
    import $http from "@/libs/axios";
    
    export default {
        beforeRouteLeave(to, from, next) {
            this.applyGetDirectOrdersSalaryPayments();
            next();
            // called when the route that renders this component is about to be navigated away from.
            // As with `beforeRouteUpdate`, it has access to `this` component instance.
        },
        components: {
            BSpinner,
            BRow,
            BCol,
            BFormInput,
            BDropdown,
            BDropdownItem,
            BCard,
            BCardHeader,
            BCardBody,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BBadge,
            BPagination,
            BTooltip,
            BFormSelect,
            BForm,
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            VueGoodTable,
            vSelect,
            BTabs,
            BTab,
            flatPickr,
            BListGroup,
            BListGroupItem
        },
    
        data() {
            return {
                isFiltre: false,
                salaryInfos: {},
                salarySingularInfos: {},
                isFiltreCnssLoading: false,
                filtreCnss: [],
                isFilterCnss: false,
                totalCnssCustomer: 0,
                totalCnssEmployee: 0,
                totalVps: 0,
                totalIts: 0,
                totalCnss: 0,
                rangeDate: null,
                config: {
                    mode: 'range'
                },
                rangeDateFilter: "",
                phone: '',
                customerFullName: '',
                packageId: '',
                customerPhone_number: '',
                customerFull_name: '',
                clickedDetailCustomerName: '',
                clickedDetailIfu: '',
                cumulCancelPayload: {},
                paymentCumulPayload: {},
                isPaymentSalaryDetails: false,
                salaryDetails: [],
                filtreSalaryDetails: [],
                filtreSalaryDetailsWithoutCnss: [],
                isReLoadDataPaymentHistoryDirectOrders: false,
                isAdvancePaymentLoading: false,
                isFilterDirectSalaryPayment: false,
                payloadFilter: {
                    user_id: "",
                    month_salary: "",
                    year: "",
                    status: "",
                    type: "",
                    employee_received_salary_advance: "",
                    cnss: "",
                    auto_send: "",
                    employee_id: "",
                    ca_id: "",
                    employee_id: ""
                },
                customerOptions: [],
                employeeOptions: [],
                monthOptions: [{
                        value: "janvier",
                        text: "Janvier"
                    },
                    {
                        value: "février",
                        text: "Février"
                    },
                    {
                        value: "mars",
                        text: "Mars"
                    },
                    {
                        value: "avril",
                        text: "Avril"
                    },
                    {
                        value: "mai",
                        text: "Mai"
                    },
                    {
                        value: "juin",
                        text: "Juin"
                    },
                    {
                        value: "juillet",
                        text: "Juillet"
                    },
                    {
                        value: "août",
                        text: "Août"
                    },
                    {
                        value: "septembre",
                        text: "Septembre"
                    },
                    {
                        value: "octobre",
                        text: "Octobre"
                    },
                    {
                        value: "novembre",
                        text: "Novembre"
                    },
                    {
                        value: "décembre",
                        text: "Décembre"
                    },
                ],
                statusOptions: [{
                        label: "Janvier",
                        value: "janvier",
                    },
                    {
                        label: "Février",
                        value: "février",
                    },
                    {
                        label: "Mars",
                        value: "mars",
                    },
                    {
                        label: "Avril",
                        value: "avril",
                    },
                    {
                        label: "Mai",
                        value: "mai",
                    },
                    {
                        label: "Juin",
                        value: "juin",
                    },
                    {
                        label: "Juillet",
                        value: "juillet",
                    },
                    {
                        label: "Août",
                        value: "août",
                    },
                    {
                        label: "Septembre",
                        value: "septembre",
                    },
                    {
                        label: "Octobre",
                        value: "octobre",
                    },
                    {
                        label: "Novembre",
                        value: "novembre",
                    },
                    {
                        label: "Décembre",
                        value: "décembre",
                    },
                ],
                advanceOptions: [{
                        label: "Oui",
                        value: 1,
                    },
                    {
                        label: "Non",
                        value: 0,
                    },
                ],
                cnssOptions: [{
                        label: "Oui",
                        value: 1,
                    },
                    {
                        label: "Non",
                        value: 0,
                    },
                ],
                autoSendOptions: [{
                        label: "Oui",
                        value: 0,
                    },
                    {
                        label: "Non",
                        value: 1,
                    },
                ],
                paymentStatusOptions: [{
                        label: "Payé",
                        value: 1,
                    },
                    {
                        label: "Non Payé",
                        value: 0,
                    },
                ],
                paymentPayload: {
                    month_salary: "",
                    year: "",
                    status: "",
                    cnss: "",
                },
                paymentHistory: [],
                managerOptions: [],
                isPaymentsLoading: true,
                rowsWithCnss: [],
                rowsWithoutCnss: [],
                clickAdvanceAmount: '',
                clickNetSalary: '',
                columns: [{
                        label: "Client",
                        field: "customer_full_name",
                    },
                    {
                        label: "Mois",
                        field: "month_salary",
                    },
                    {
                        label: "Année",
                        field: "year",
                    },
                    {
                        label: "Montant",
                        field: "total_amount_to_be_paid",
                    },
                    {
                        label: "Total avances",
                        field: "total_salary_advance_amount",
                    },
                    {
                        label: "Net à payer",
                        field: "montantApayer",
                    },
                    {
                        label: "Commission",
                        field: "ylomi_direct_fees",
                    },
                    {
                        label: "Statut",
                        field: "setStatus",
                    },
                    {
                        label: "Date de paiement",
                        field: "salary_paid_date",
                    },
                    {
                        label: "Action",
                        field: "action",
                    },
                ],
    
                payement: {
                    month: "",
                    mobile_money_account: "",
                    is_in_ylomi_program: "",
                },
    
                businessManagerOptions: [],
    
                isOrderAssignmentLoading: false,
    
                payload: {
                    month: "",
                    salary_advance_amount: "",
                },
    
                lineItemDirectOrderId: null,
                lineItemEmployeeId: null,
    
                orderToAssign: {},
                currentOrder: {},
                statusFilter: null,
                avatarText,
                required,
                total: "",
                tableColumnsLink: [{
                        key: "link",
                        label: "Lien de paiement",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "actions",
                        class: "font-medium-1"
                    },
                ],
    
                tableColumnsCnss: [{
                        key: "customer_full_name",
                        label: "Informations du client",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_full_name",
                        label: "Employé",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    {
                        key: "service_name",
                        label: "Service",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_salary_amount",
                        label: "Salaire net",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    {
                        key: "cnss_customer_amount",
                        label: "Cnss part patronale",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "cnss_employee_amount",
                        label: "Cnss part employé",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "its_amount",
                        label: "ITS",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "vps_amount",
                        label: "VPS",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    //  {
                    //   key: "total_amount_to_paid",
                    //   label: "Montant total",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    // {
                    //   key: "salary_advance_amount",
                    //   label: "Avance sur salaire",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    // {
                    //   key: "net_salary",
                    //   label: "Salaire à prendre",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    //  {
                    //   key: "netApayer",
                    //   label: "Net à payer",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    {
                        key: "month_salary",
                        label: "Mois",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    // {
                    //   key: "status",
                    //   label: "Statut",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    // {
                    //   key: "salary_paid_date",
                    //   label: "Date de paiement",
                    //   sortable: true,
                    //   class: "font-medium-1",
                    // },
                    // { key: "actions", class: "font-medium-1" },
                ],
    
                tableColumns1: [{
                        key: "customer_full_name",
                        label: "Informations du client",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_full_name",
                        label: "Employé",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "service_name",
                        label: "Service",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_salary_amount",
                        label: "Salaire net",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    {
                        key: "cnss_customer_amount",
                        label: "Cnss part patronale",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "cnss_employee_amount",
                        label: "Cnss part employé",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "its_amount",
                        label: "ITS",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "vps_amount",
                        label: "VPS",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "ylomi_direct_fees",
                        label: "Prestation",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "total_amount_to_paid",
                        label: "Montant total",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "salary_advance_amount",
                        label: "Avance sur salaire",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "net_salary",
                        label: "Salaire à prendre",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "netApayer",
                        label: "Net à payer",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "month_salary",
                        label: "Mois",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "status",
                        label: "Statut",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "salary_paid_date",
                        label: "Date de paiement",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "actions",
                        class: "font-medium-1"
                    },
                ],
    
                tableColumns2: [{
                        key: "customer_full_name",
                        label: "Informations du client",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_full_name",
                        label: "Employé",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "service_name",
                        label: "Service",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "employee_salary_amount",
                        label: "Salaire net",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "assurance_amount",
                        label: "Assurance",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    {
                        key: "ylomi_direct_fees",
                        label: "Prestation",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "total_amount_to_paid",
                        label: "Montant total",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "salary_advance_amount",
                        label: "Avance sur salaire",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "net_salary",
                        label: "Salaire à prendre",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "netApayer",
                        label: "Net à payer",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "month_salary",
                        label: "Mois",
                        sortable: true,
                        class: "font-medium-1",
                    },
    
                    {
                        key: "status",
                        label: "Statut",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "salary_paid_date",
                        label: "Date de paiement",
                        sortable: true,
                        class: "font-medium-1",
                    },
                    {
                        key: "actions",
                        class: "font-medium-1"
                    },
                ],
                paymentLink: null,
                generate: [],
                clickedDetailPackage: {},
            };
        },
    
        computed: {
            ...mapGetters([
                "isPaymentHistoryLoading",
                "isCustomersWithoutPaginationLoading",
                "isEmployeesWithoutPaginationLoading",
            ]),
            ...mapGetters("orders", ["getDirectOrdersPaymentHistory"]),
            ...mapGetters("auth", ["getUser"]),
            ...mapGetters("customers", ["getCustomersWithoutPagination"]),
            ...mapGetters("roles", ["getBusinessManagersList"]),
            ...mapGetters("professionals", ["getEmployeesWithoutPagination"]),
            ...mapGetters("packages", ["getPayloadFilter"]),
    
            salarymin() {
                const min = 0
                min === this.clickNetSalary
                return min
            },
    
            metaData() {
                const meta = {
                    prev_page_url: "",
                    next_page_url: "",
                    current_page: "",
                    last_page: "",
                    current_page_url: "",
                };
                if (this.getDirectOrdersPaymentHistory.withCnss != null) {
                    meta.prev_page_url = this.getDirectOrdersPaymentHistory.withCnss.prev_page_url;
                    meta.next_page_url = this.getDirectOrdersPaymentHistory.withCnss.next_page_url;
                    meta.current_page = this.getDirectOrdersPaymentHistory.withCnss.current_page;
                    meta.last_page = this.getDirectOrdersPaymentHistory.withCnss.last_page;
                    meta.current_page_url = `${this.getDirectOrdersPaymentHistory.withCnss.path}?page=${this.getDirectOrdersPaymentHistory.current_page}`;
                    this.total = this.getDirectOrdersPaymentHistory.withCnss.total
                }
                return meta;
            },
    
            metaDataWithoutCnss() {
                const metaWithoutCnss = {
                    prev_page_url: "",
                    next_page_url: "",
                    current_page: "",
                    last_page: "",
                    current_page_url: "",
                };
                if (this.getDirectOrdersPaymentHistory.withoutCnss != null) {
                    metaWithoutCnss.prev_page_url = this.getDirectOrdersPaymentHistory.withoutCnss.prev_page_url;
                    metaWithoutCnss.next_page_url = this.getDirectOrdersPaymentHistory.withoutCnss.next_page_url;
                    metaWithoutCnss.current_page = this.getDirectOrdersPaymentHistory.withoutCnss.current_page;
                    metaWithoutCnss.last_page = this.getDirectOrdersPaymentHistory.withoutCnss.last_page;
                    metaWithoutCnss.current_page_url = `${this.getDirectOrdersPaymentHistory.withoutCnss.path}?page=${this.getDirectOrdersPaymentHistory.withoutCnss.current_page}`;
                    this.totalWithoutCnss = this.getDirectOrdersPaymentHistory.withoutCnss.total;
                }
                return metaWithoutCnss;
            },
            previousMonth() {
                const current = new Date();
                current.setMonth(current.getMonth() - 1);
                return current.toLocaleString("fr", {
                    month: "long"
                });
            },
    
            yearOptions() {
                const data = [];
                for (let i = 2021; i <= Number(new Date().getFullYear().toString()); i++) {
                    data.push({
                        label: i.toString(),
                        value: i.toString(),
                    });
                }
                return data;
            },
        },
    
        watch: {
            isEmployeesWithoutPaginationLoading(val) {
                if (val === false) {
                    this.getEmployeesWithoutPagination.forEach((element) => {
                        this.employeeOptions.push({
                            id: element.id,
                            name: element.full_name,
                        });
                    });
                }
            },
            isPaymentHistoryLoading(val) {
                if (val === false) {
                    this.rowsWithCnss = this.customTab(this.getDirectOrdersPaymentHistory.withCnss.data);
                    this.rowsWithoutCnss = this.customTab(
                        this.getDirectOrdersPaymentHistory.withoutCnss.data
                    );
                }
            },
    
            isDirectOrdersLoading(val) {
                if (val === false) {}
            },
            isCustomersWithoutPaginationLoading(val) {
                if (val === false) {
                    this.getCustomersWithoutPagination.forEach((element) => {
                        this.customerOptions.push({
                            id: element.id,
                            name: element.customer_full_name,
                        });
                    });
                }
            },
            rangeDate(val) {
                if (val != null && val.split('to').length === 2) {
                    console.log(val.split('to').length)
                    this.applyFilterCnssAction(val)
                }
            },
            payloadFilter: {
                handler(newValue, oldValue) {
                    const keys = Object.keys(newValue);
                    keys.forEach((key) => {
                        if (newValue[key] == null) {
                            newValue[key] = "";
                            this.reLoadDataPaymentHistoryDirectOrdersAction()
                            console.log(this.payloadFilter)
                        }
                    });
                },
                deep: true,
            },
        },
    
        created() {
            this.applyGetBusinessManagersListAction();
            console.log('SALAIRE', this.getDirectOrdersPaymentHistory.withCnss)
            this.getDirectOrdersPaymentHistory.data != null ?
                (this.rowsWithCnss = this.customTab(this.getDirectOrdersPaymentHistory.data.withCnss.data)) :
                this.applyGetSalaryPaymentHistoryAction();
    
            this.getDirectOrdersPaymentHistory.data != null ?
                (this.rowsWithoutCnss = this.customTab(
                    this.getDirectOrdersPaymentHistory.data.withoutCnss
                )) :
                this.applyGetSalaryPaymentHistoryAction();
    
            // this.reLoadDataPaymentHistoryDirectOrdersAction()
        },
    
        mounted() {
            if (this.getEmployeesWithoutPagination.length != 0) {
                this.getEmployeesWithoutPagination.forEach((element) => {
                    this.employeeOptions.push({
                        id: element.id,
                        name: element.full_name,
                    });
                });
            }
    
            if (this.getCustomersWithoutPagination.length != 0) {
                this.getCustomersWithoutPagination.forEach((element) => {
                    this.customerOptions.push({
                        id: element.id,
                        name: element.customer_full_name,
                    });
                });
            }
        },
    
        methods: {
            applyGetDirectOrdersSalaryPayments() {
                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", true);
    
                this.getDirectOrdersSalaryPaymentsAction()
                    .then(() => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    })
                    .catch(() => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    });
            },
            ...mapActions("customers", [
                "advancePaymentAction",
                "getDirectOrdersSalaryPaymentsAction",
            ]),
            ...mapActions("roles", ["getBusinessManagersListAction"]),
    
            ...mapActions("orders", [
                "assignAnOrderToBusinessManagerAction",
                "getDirectOrdersAction",
                "getDirectOrdersAssignedToBusinessManagerAction",
                "cancelDirectOrderPaymentAction",
                "getDirectOrdersSalaryPaymentsAction",
                "loadMoreDirectOrderPaymentHistoryAction",
                "filterDirectOrdersSalaryPaymentsAction",
                "getSalaryPayementDetailsAction",
                "confirmSalaryCumulPaymentAction",
                "cancelCumulPaymentAction",
                "confirmSingularSalaryPaymentAction",
                "cancelSingularSalaryPaymentAction",
                "filterCnssAction"
            ]),
            ...mapActions("professionals", [
                "confirmSalaryPaiementAction",
                "blockSalaryPaiementAction",
                "unBlockSalaryPaiementAction",
            ]),

            employeeWallet(item, cnss){
                console.log(item)
                this.$router.push(`/employees/wallet/${item.employee_wallet_id}`)
            },
    
            getInfosCumul(item) {
                this.salaryInfos = item
    
            },
            getInfosSingulier(item) {
                this.salarySingularInfos = item
                console.log(this.salarySingularInfos )
            },
    
            applyFilterCnssAction(val) {
                
                this.isFiltreCnssLoading = false
                const data = {
                    start_date: val.split('to')[0],
                    end_date: val.split('to')[1],
                }
                this.$bvModal.show('modal-filtre-cnss')
                this.isFilterCnss = true
                this.filterCnssAction(data)
                    .then(response => {
                        this.isFilterCnss = false
                        this.filtreCnss = response.data
                        this.total = this.filtreCnss.length
    
                        response.data.forEach((element) => {
                            this.totalCnssCustomer += parseInt(element.cnss_customer_amount),
                                this.totalCnssEmployee += parseInt(element.cnss_employee_amount),
                                this.totalVps += parseInt(element.vps_amount),
                                this.totalIts += parseInt(element.its_amount)
                        })
                        this.totalCnss = parseInt(this.totalCnssCustomer) + parseInt(this.totalCnssEmployee) + parseInt(this.totalVps) + parseInt(this.totalIts)
                        this.$bvModal.show('modal-cnss-filtre')
                    }).catch(error => {
                        this.isFilterCnss = false
                        if (error.response.status !== 422) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erreur',
                                    icon: 'AlertTriangleIcon',
                                    text: 'Erreur lors du filtrage, Réessayer!',
                                    variant: 'danger',
                                },
                            }, {
                                position: 'top-center',
                            }, )
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erreur',
                                    icon: 'AlertTriangleIcon',
                                    text: error.response.data.message,
                                    variant: 'danger',
                                },
                            }, {
                                position: 'top-center',
                            }, )
                        }
                    })
            },
    
            netApayer(montant, avance) {
                const net = avance == null ? montant : (montant - avance)
                return net
            },
            net_salary(salaire, avanceSalaire) {
                const net = salaire == null ? avanceSalaire : (salaire - avanceSalaire)
                return net
            },
            montantApayer(salary, avanceSalary) {
                const montant = avanceSalary == null ? salary : (salary - avanceSalary)
                return montant
            },
            // DETAILS D'UN PAIEMENT DE SALAIRE
            showSalaireDetails(salary, cnss) {
                this.isPaymentSalaryDetails = true;
                this.isFiltre = false;
                console.log('clickedDetailIfu', this.clickedDetailIfu)
                this.clickedDetailPhoneNumber = salary.phone_number
                this.clickedDetailCustomerName = salary.customer_full_name
                this.clickedDetailIfu = salary.company_ifu !== "null" ? salary.company_ifu : ""
                this.clickedDetailPackage = salary
                this.clickedDetailPackage.status = salary.status
               
                this.paymentPayload.month_salary = salary.month_salary
                this.paymentPayload.year = salary.year
                this.paymentPayload.status = salary.status ? 1 : 0
                this.paymentPayload.cnss = cnss
                this.getSalaryPayementDetailsAction({
                        packageId: salary.package_id,
                        payload: this.paymentPayload,
                    })
                    .then((response) => {
                        this.salaryDetails = response.data;
                        console.log("DETAILS SALAIRES", this.salaryDetails);
                        this.isPaymentSalaryDetails = false;
    
                        this.$bvModal.hide("modal-action-with-cnss");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isPaymentSalaryDetails = false;
                    });
            },
    
            resolveDetailSlaryStatusVariant: (status) => {
                if (status == true) return "success";
    
                if (status == false) return "danger";
    
                return "primary";
            },
    
            applyConfirmSalaryCumulAction(item, cnss) {
                (this.paymentCumulPayload.month_salary = item.month_salary),
                (this.paymentCumulPayload.cnss = cnss),
                (this.paymentCumulPayload.year = item.year);
    
                this.$swal
                    .fire({
                        title: "Confirmation",
                        text: "Êtes-vous sûr de vouloir confirmer le paiement du cumul?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
    
                        buttonsStyling: false,
    
                        preConfirm: async () => {
                            await this.confirmSalaryCumulPaymentAction({
                                    packageId: item.package_id,
                                    payload: this.paymentCumulPayload,
                                })
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
    
                        if (response.value) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement salaire validé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.$bvModal.hide("modal-action-with-cnss");
                            this.applyGetSalaryPaymentHistoryAction();
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            applyCancelCumulPaymentAction(item, cnss) {
                (this.cumulCancelPayload.month_salary = item.month_salary),
                (this.cumulCancelPayload.cnss = cnss),
                (this.cumulCancelPayload.year = item.year);
                this.$swal
                    .fire({
                        title: "Annuler",
                        text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            await this.cancelCumulPaymentAction({
                                    packageId: item.package_id,
                                    payload: this.cumulCancelPayload,
                                })
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                            this.$bvModal.hide("modal-action-with-cnss");
                            this.applyGetSalaryPaymentHistoryAction();
    
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );
                        },
                    })
    
                    .then((response) => {
                        if (response.value) {
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement annulé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.applyGetSalaryPaymentHistoryAction();
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            confirmSingularSalaryPayment(item, cnss) {
                this.$bvModal.hide("modal-action-singular-with-cnss");
                this.$swal
                    .fire({
                        title: "Confirmation",
                        text: "Êtes-vous sûr de vouloir confirmer le paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
    
                        buttonsStyling: false,
    
                        preConfirm: async () => {
                            await this.confirmSingularSalaryPaymentAction(item.id)
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        
    
                        if (response.value) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement salaire validé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
    
                            this.clickedDetailPackage.status = true
                            console.log('status', this.clickedDetailPackage)
                            console.log('is filtre', this.isFiltre)
                            if (this.isFiltre == true) {
                                this.applyFilterBusinessSalaryPaymentAction(cnss)
    
                            } else {
                                this.showSalaireDetails(this.clickedDetailPackage, cnss);
                            }
    
                            this.applyGetSalaryPaymentHistoryAction();
                            this.$bvModal.hide("modal-action-singular-with-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            cancelSingularSalaryPayment(item, cnss) {
                console.log(item.id)
                this.$swal
                    .fire({
                        title: "Annuler",
                        text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            await this.cancelSingularSalaryPaymentAction(item.id)
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                            
                        },
                    })
    
                    .then((response) => {
                        if (response.value) {
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement annulé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.clickedDetailPackage.status = true
                            console.log('status', this.clickedDetailPackage)
                            console.log('is filtre', this.isFiltre)
                            if (this.isFiltre == true) {
                                this.applyFilterBusinessSalaryPaymentAction(cnss)
    
                            } else {
                                this.showSalaireDetails(this.clickedDetailPackage, cnss);
                            }
                            this.applyGetSalaryPaymentHistoryAction();
                            this.getDirectOrdersSalaryPaymentsAction()
                            .then((response) => {
                                this.isReLoadDataPaymentHistoryDirectOrders = false;
                                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                            })
                            
                            this.$bvModal.hide("modal-action-singular-with-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            blockSingularSalaryPayment(item, cnss) {
                console.log(item.id)
                this.$swal({
                        title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                        text: item.auto_send == true ?
                            "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                            "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
    
                            if (item.auto_send == true) {
                                const payload = {
                                    auto_send: false,
                                };
                                return await this.blockSalaryPaiementAction({
                                    id: item.id
                                });
    
                            }
    
                            const payload = {
                                auto_send: true,
                            };
                            await this.unBlockSalaryPaiementAction({
                                id: item.id
                            });
                        },
                    })
                    .then((response) => {
                        
                        if (response.isConfirmed) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: item.auto_send == true ?
                                        "Paiement du salaire de l'employé bloqué avec succès" :
                                        "Paiement du salaire de l'employé débloqué avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            console.log('status', this.clickedDetailPackage)
                            console.log('is filtre', this.isFiltre)
                            if (this.isFiltre == true) {
                                this.applyFilterBusinessSalaryPaymentAction(cnss)
    
                            } else {
                                this.showSalaireDetails(this.clickedDetailPackage, cnss);
                            }
                            this.applyGetSalaryPaymentHistoryAction();
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                            this.$bvModal.hide("modal-action-singular-with-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            applyAdvanceSingularPaymentAction() {
                this.$refs.advancePaymentMonth.validate().then((success) => {
                    if (success) {
                        this.isAdvancePaymentLoading = true;
                        this.advancePaymentAction({
                                payload: this.payload,
                                employeeId: this.lineItemEmployeeId,
                            })
                            .then((response) => {
                                this.isAdvancePaymentLoading = false;
                                // this.applyGetOfferType()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Succès",
                                        icon: "CheckIcon",
                                        text: response.message,
                                        variant: "success",
                                    },
                                }, {
                                    position: "top-center",
                                });
                                // this.lineItemDirectOrderId = null,
                                // this.lineItemEmployeeId = null,
                                this.$bvModal.hide("modal-payment-advance-month");
                                this.payload = {
                                    month: "",
                                    salary_advance_amount: "",
                                };
                                this.clickedDetailPackage.salary_advance_amount == salary_advance_amount
                                console.log('status', this.clickedDetailPackage)
                                if (this.isFiltre == true) {
                                    this.applyFilterBusinessSalaryPaymentAction(cnss)
    
                                } else {
                                    this.showSalaireDetails(this.clickedDetailPackage, cnss);
                                }
                                this.applyGetSalaryPaymentHistoryAction();
                                this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                    this.metaData.current_page_url
                                );
                                this.$bvModal.hide("modal-action-singular-with-cnss");
                            })
                            .catch((error) => {
                                // this.lineItemDirectOrderId = null,
                                // this.lineItemEmployeeId = null,
                                console.log(error);
                                this.isAdvancePaymentLoading = false;
                            });
                    }
                });
            },
            showAdvanceSingularPayementMonthModal(item) {
                this.clickAdvanceAmount = item.salary_advance_amount
                this.clickNetSalary = item.employee_salary_amount
                console.log(this.clickNetSalary)
                this.lineItemEmployeeId = item.employee_id;
                this.payload.month = item.month_salary;
                this.$bvModal.show("modal-payment-advance-month");
            },
            hideModalPaymentAdvanceMonthForm() {
                this.lineItemDirectOrderId = null;
                this.lineItemEmployeeId = null;
                this.$bvModal.hide("modal-payment-advance-month");
                this.payload = {
                    month: "",
                    salary_advance_amount: "",
                };
            },
    
            // ACTIONS WITHOUT CNSS PAYMENT SALARY
            // DETAILS D'UN PAIEMENT DE SALAIRE
            showSalaireWithoutCnssDetails(salary, cnss) {
                this.isFiltre = false;
                this.clickedDetailPhoneNumber = salary.phone_number
                this.clickedDetailCustomerName = salary.customer_full_name
                this.clickedDetailIfu = salary.company_ifu !== "null" ? salary.company_ifu : ""
                this.clickedDetailPackage = salary
                this.isPaymentSalaryDetails = true
                this.paymentPayload.month_salary = salary.month_salary
                this.paymentPayload.year = salary.year
                this.paymentPayload.status = salary.status ? 1 : 0
                this.paymentPayload.cnss = cnss
                this.getSalaryPayementDetailsAction({
                        packageId: salary.package_id,
                        payload: this.paymentPayload,
                    })
                    .then((response) => {
                        this.salaryDetails = response.data;
                        console.log(this.clickedDetailPackage, "detailssss")
                        console.log("DETAILS SALAIRES", this.salaryDetails);
                        this.isPaymentSalaryDetails = false;
                        this.$bvModal.hide("modal-action-without-cnss");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isPaymentSalaryDetails = false;
                    });
            },
    
            resolveDetailSlaryWithoutCnssStatusVariant: (status) => {
                if (status == true) return "success";
    
                if (status == false) return "danger";
    
                return "primary";
            },
            applyConfirmSalaryCumulWithoutCnssAction(item, cnss) {
                
                (this.paymentCumulPayload.month_salary = item.month_salary),
                (this.paymentCumulPayload.cnss = cnss),
                (this.paymentCumulPayload.year = item.year);
    
                this.$swal
                    .fire({
                        title: "Confirmation",
                        text: "Êtes-vous sûr de vouloir confirmer le paiement du cumul?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
    
                        buttonsStyling: false,
    
                        preConfirm: async () => {
                            await this.confirmSalaryCumulPaymentAction({
                                    packageId: item.package_id,
                                    payload: this.paymentCumulPayload,
                                })
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
    
                        if (response.value) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement salaire validé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.$bvModal.hide("modal-action-without-cnss");
                            this.applyGetSalaryPaymentHistoryAction();;
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            applyCancelCumulPaymentWithoutCnssAction(item, cnss) {
                (this.cumulCancelPayload.month_salary = item.month_salary),
                (this.cumulCancelPayload.cnss = cnss),
                (this.cumulCancelPayload.year = item.year);
                this.$swal
                    .fire({
                        title: "Annuler",
                        text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            await this.cancelCumulPaymentAction({
                                    packageId: item.package_id,
                                    payload: this.cumulCancelPayload,
                                })
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
    
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );
                        },
                    })
    
                    .then((response) => {
                        if (response.value) {
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement annulé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.$bvModal.hide("modal-action-without-cnss");
                            this.applyGetSalaryPaymentHistoryAction();
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            confirmSingularSalaryPaymentWithoutCnss(item, cnss) {
                this.$bvModal.hide("modal-action-without-cnss");
                this.$swal
                    .fire({
                        title: "Confirmation",
                        text: "Êtes-vous sûr de vouloir confirmer le paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
    
                        buttonsStyling: false,
    
                        preConfirm: async () => {
                            await this.confirmSingularSalaryPaymentAction(item.id)
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                        },
                    })
                    .then((response) => {
                        console.log(response);
    
                        if (response.value) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement salaire validé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.clickedDetailPackage.status = true
                            console.log('Payload filter', this.getPayloadFilter)
    
                            if (this.isFiltre == true) {
                             this.applyFilterDirectSalaryPaymentWithoutCnssAction(cnss)
                                /* this.isFilterDirectSalaryPayment = true;
                                this.isPaymentSalaryDetails = true;
                                this.filtreSalaryDetailsWithoutCnss = [];
    
                                this.payloadFilter = this.getPayloadFilter
                                
                                this.filterDirectOrdersSalaryPaymentsAction(this.payloadFilter)
                                .then((response) => {
                                    this.isFilterDirectSalaryPayment = false;
                                    this.isPaymentSalaryDetails = false;
                                    this.filtreSalaryDetailsWithoutCnss = this.customTab(
                                        this.getDirectOrdersPaymentHistory.withoutCnss
                                    );
                                    this.totalWithoutCnss = this.filtreSalaryDetailsWithoutCnss.length; 
                                    
                                })
                                .catch((error) => {
                                    this.isFilterDirectSalaryPayment = false;
                                    this.isPaymentSalaryDetails = false;
                                    console.log(error);
                                }); */
    
                            } else {
                                this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                            }
                                this.getDirectOrdersSalaryPaymentsAction()
                                .then((response) => {
                                    this.isReLoadDataPaymentHistoryDirectOrders = false;
                                    this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                                })
                                    this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                        this.metaData.current_page_url
                                    );
                            this.applyGetSalaryPaymentHistoryAction();
                            this.$bvModal.hide("modal-action-singular-without-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            cancelSingularSalaryPaymentWithoutCnss(item, cnss) {
                this.$swal
                    .fire({
                        title: "Annuler",
                        text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            await this.cancelSingularSalaryPaymentAction(item.id)
                                .then((response) => {})
                                .catch((error) => {
                                    this.$swal.showValidationMessage(`${error.response.data.message}`);
                                });
                        },
                    })
    
                    .then((response) => {
                        if (response.value) {
                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement annulé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.clickedDetailPackage.status = true
                            console.log('status', this.clickedDetailPackage)
    
                            if (this.isFiltre == true) {
                                this.applyFilterDirectSalaryPaymentWithoutCnssAction(cnss)
    
                            } else {
                                this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                            }
                            this.applyGetSalaryPaymentHistoryAction();
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );
                            this.$bvModal.hide("modal-action-singular-without-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
            blockSingularSalaryPaymentWithoutCnss(item, cnss) {
                console.log(item.id)
                this.$swal({
                        title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                        text: item.auto_send == true ?
                            "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                            "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
    
                            if (item.auto_send == true) {
                                const payload = {
                                    auto_send: false,
                                };
                                return await this.blockSalaryPaiementAction({
                                    id: item.id
                                });
    
                            }
    
                            const payload = {
                                auto_send: true,
                            };
                            await this.unBlockSalaryPaiementAction({
                                id: item.id
                            });
                        },
                    })
                    .then((response) => {
                        
                        if (response.isConfirmed) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: item.auto_send == true ?
                                        "Paiement du salaire de l'employé bloqué avec succès" :
                                        "Paiement du salaire de l'employé débloqué avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
    
                            console.log('status', this.clickedDetailPackage)
                            if (this.isFiltre == true) {
                                this.applyFilterDirectSalaryPaymentWithoutCnssAction(cnss)
    
                            } else {
                                this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                            }
    
                            this.applyGetSalaryPaymentHistoryAction();
                            this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                            this.$bvModal.hide("modal-action-singular-without-cnss");
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            showAdvanceSingularPayementMonthModalWithoutCnss(item) {
                this.clickAdvanceAmount = item.salary_advance_amount
                this.clickNetSalary = item.employee_salary_amount
                console.log(this.clickNetSalary)
                this.lineItemEmployeeId = item.employee_id;
                this.payload.month = item.month_salary;
                this.$bvModal.show("modal-payment-advance-month-without-cnss");
            },
            hideModalPaymentAdvanceMonthFormWithoutCnss() {
                this.lineItemDirectOrderId = null;
                this.lineItemEmployeeId = null;
                this.$bvModal.hide("modal-payment-advance-month-without-cnss");
                this.payload = {
                    month: "",
                    salary_advance_amount: "",
                };
    
            },
    
            // FIN PAYMENT SALARY WITHOUT CNSS
            applyBlockSingularSalaryPaiementAction(item) {
                this.$swal({
                        title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                        text: item.auto_send == true ?
                            "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                            "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            if (item.auto_send == true) {
                                const payload = {
                                    auto_send: false,
                                };
                                return await this.blockSalaryPaiementAction({
                                    id: item.package_id,
                                    payload,
                                });
                            }
    
                            const payload = {
                                auto_send: true,
                            };
                            await this.blockSalaryPaiementAction({
                                id: item.id,
                                payload,
                            });
                        },
                    })
                    .then((response) => {
                        this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                        if (response.isConfirmed) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: item.auto_send == true ?
                                        "Paiement du salaire de l'employé bloqué avec succès" :
                                        "Paiement du salaire de l'employé débloqué avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            applyConfirmSalaryPlacementAction(item) {
                this.$swal({
                        title: "Confirmation",
                        text: "Êtes-vous sûr de vouloir confirmer le paiement de la prestation ?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                            confirmButton: "btn btn-warning",
                            cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false,
                        preConfirm: async () => {
                            await this.confirmSalaryPaiementAction({
                                id: item.direct_order_id,
                                directSalaryPaiementId: item.id,
                                paymentMethod: "3",
                            });
                        },
                    })
                    .then((response) => {
                        this.applyLoadMoreDirectOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
    
                        if (response.value) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Paiement salaire validé",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            applyFilterBusinessSalaryPaymentAction(cnss) {
                this.isFiltre = true
                const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
                if (allIsNUl) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Warning",
                            icon: "CheckIcon",
                            text: "Veuillez sélectionner au moins un champ du filtre.",
                            variant: "warning",
                        },
                    }, {
                        position: "top-center",
                    });
                } else {
                    this.$bvModal.show('modal-filtre-details-salary');
                    this.isFilterDirectSalaryPayment = true;
                    this.isPaymentSalaryDetails = true;
                    this.filtreSalaryDetails = [];
                    // this.payloadFilter.type = "CL";
                    if (cnss = 1) {
                        this.payloadFilter.cnss = 1
                    } else {
                        this.payloadFilter.cnss = 0
                    }
                    this.filterDirectOrdersSalaryPaymentsAction(this.payloadFilter)
                        .then((response) => {
                            this.isFilterDirectSalaryPayment = false;
                            this.isPaymentSalaryDetails = false;
                            this.filtreSalaryDetails = this.customTab(
                                this.getDirectOrdersPaymentHistory.withCnss
                            ).reverse();
                            this.total = this.filtreSalaryDetails.length;
                        })
                        .catch((error) => {
                            this.isFilterDirectSalaryPayment = false;
                            this.isPaymentSalaryDetails = false;
                            console.log(error);
                        });
                }
            },
    
            applyFilterDirectSalaryPaymentWithoutCnssAction(cnss) {
              
                this.isFiltre = true;
                console.log(this.clickedDetailPackage, "clic")
                const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
                if (allIsNUl) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Warning",
                            icon: "CheckIcon",
                            text: "Veuillez sélectionner au moins un champ du filtre.",
                            variant: "warning",
                        },
                    }, {
                        position: "top-center",
                    });
                } else {
                    this.$bvModal.show('modal-filtre-details-salary-without-cnss');
                    this.isFilterDirectSalaryPayment = true;
                    this.isPaymentSalaryDetails = true;
                    this.filtreSalaryDetailsWithoutCnss = [];
                    // this.payloadFilter.type = "CL";
                    this.payloadFilter.cnss = 0
    
                    this.filterDirectOrdersSalaryPaymentsAction(this.payloadFilter)
                        .then((response) => {
                            this.isFilterDirectSalaryPayment = false;
                            this.isPaymentSalaryDetails = false;
                            this.filtreSalaryDetailsWithoutCnss = this.customTab(
                                this.getDirectOrdersPaymentHistory.withoutCnss
                            ).reverse();
                            this.totalWithoutCnss = this.filtreSalaryDetailsWithoutCnss.length;
    
                            console.log('PAYLOAD FILTER',this.getPayloadFilter);
                        })
                        .catch((error) => {
                            this.isFilterDirectSalaryPayment = false;
                            this.isPaymentSalaryDetails = false;
                            console.log(error);
                        });
                }
            },
            customTab(array) {
                const tab = [];
                array.forEach((element) => {
                    switch (element.status) {
                        case false:
                            element.setStatus = "Non Payé";
                            break;
                        case true:
                            element.setStatus = "Payé";
                            break;
                        default:
                            element.setStatus = null;
                    }
                    tab.push(element);
                });
                return tab;
            },
    
            // payement function
            applyEmployeePayementFile() {
                this.$refs.downloadFile.validate().then((success) => {
                    if (success) {
                        this.isPaymentLoading = true;
                        $http
                            .post("/admin/direct/employees/payement/file", this.payement, {
                                responseType: "blob",
                            })
                            .then((response) => {
                                this.isPaymentLoading = false;
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                    "download",
                                    `bulk-${this.payement.month}-${new Date().getFullYear()}.csv`
                                );
                                document.body.appendChild(link);
                                link.click();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Succès",
                                        icon: "CheckIcon",
                                        text: "Fichier téléchargé avec succès",
                                        variant: "success",
                                    },
                                }, {
                                    position: "top-center",
                                });
                                this.hideModalPaymentFileModal();
                            })
                            .catch((error) => {
                                if (error.response.status == 422) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Notification",
                                            icon: "CheckIcon",
                                            text: "Aucun employé à payé pour ce mois",
                                            variant: "warning",
                                        },
                                    }, {
                                        position: "top-center",
                                    });
                                    this.hideModalPaymentFileModal();
                                }
                                this.isPaymentLoading = false;
                            });
                    }
                });
            },
    
            applyGetSalaryPaymentHistoryAction() {
                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", true);
                this.getDirectOrdersSalaryPaymentsAction()
                    .then((response) => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                        this.rowsWithCnss = this.customTab(response.data.withCnss.data);
                        this.rowsWithoutCnss = this.customTab(response.data.withoutCnss.data);
                        console.log(this.rowsWithCnss);
                    })
                    .catch((error) => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                        console.log("ERROR", error);
                    });
            },
            reLoadDataPaymentHistoryDirectOrdersAction() {
                this.payloadFilter.user_id = "";
                this.payloadFilter.employee_id = "";
                this.payloadFilter.month_salary = "";
                this.payloadFilter.employee_received_salary_advance = "";
                this.payloadFilter.auto_send = "";
                this.payloadFilter.year = "";
                this.payloadFilter.status = "";
                this.payloadFilter.cnss = "";
                this.payloadFilter.ca_id = "";
                this.start_date = "";
                this.end_date = "";
                this.isReLoadDataPaymentHistoryDirectOrders = true;
                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", true);
                this.rowsWithCnss = [];
                this.rowsWithoutCnss = [];
                this.getDirectOrdersSalaryPaymentsAction()
                    .then((response) => {
                        this.isReLoadDataPaymentHistoryDirectOrders = false;
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    })
                    .catch((error) => {
                        this.isReLoadDataPaymentHistoryDirectOrders = false;
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    });
            },
            eraseFilterCnss(){
                this.isFilterCnss = true;
                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", true);
                this.rowsWithCnss = [];
                this.rowsWithoutCnss = [];
                this.getDirectOrdersSalaryPaymentsAction()
                    .then((response) => {
                        this.rangeDate = null
                        this.isFilterCnss = false;
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    })
                    .catch((error) => {
                        this.isFilterCnss = false;
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    });
            },

            applyLoadMoreDirectOrderPaymentHistoryAction(url) {
                this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", true);
                this.rowsWithCnss = [];
                this.rowsWithoutCnss = [];
                this.loadMoreDirectOrderPaymentHistoryAction(url)
                    .then(() => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    })
                    .catch(() => {
                        this.$store.commit("SET_IS_PAYMENT_HISTORY_LOADING", false);
                    });
            },
    
            applyAdvancePaymentAction(cnss) {
                this.isAdvancePaymentLoading = true;
                const netSalary = this.net_salary(this.clickNetSalary, this.clickAdvanceAmount)
                console.log('NET SALARY', netSalary)
                if (this.payload.salary_advance_amount >= netSalary) {
                    this.isAdvancePaymentLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: "Le montant entré est supérieur au salaire à prendre par l'employé",
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.isAdvancePaymentLoading = false;
                    this.$bvModal.hide("modal-payment-advance-month");
                    this.payload = {
                        month: "",
                        salary_advance_amount: "",
                    };
                } else {
                    this.$refs.advancePaymentMonth.validate().then((success) => {
                        if (success) {
                            this.isAdvancePaymentLoading = true;
                            this.payload.type = "recurring"
                            this.advancePaymentAction({
                                    payload: this.payload,
                                    directOrderId: this.lineItemDirectOrderId,
                                    employeeId: this.lineItemEmployeeId,
                                })
                                .then((response) => {
                                    this.applyLoadMoreDirectOrderPaymentHistoryAction(
                                        this.metaData.current_page_url
                                    );
    
                                    this.isAdvancePaymentLoading = false;
                                    // this.applyGetOfferType()
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Succès",
                                            icon: "CheckIcon",
                                            text: response.message,
                                            variant: "success",
                                        },
                                    }, {
                                        position: "top-center",
                                    });
                                    // this.lineItemDirectOrderId = null,
                                    // this.lineItemEmployeeId = null,
                                    this.clickedDetailPackage.salary_advance_amount == this.clickAdvanceAmount
                                        console.log('status', this.clickedDetailPackage)
                                    if (cnss == 0) {
                                        this.hideModalPaymentAdvanceMonthFormWithoutCnss()
                                        this.$bvModal.hide("modal-action-singular-without-cnss");
    
                                        if (this.isFiltre == true) {
                                        this.applyFilterDirectSalaryPaymentWithoutCnssAction(cnss)
    
                                        } else {
                                            this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                                        }
                                    } 
                                    else {
                                        this.hideModalPaymentAdvanceMonthForm()
                                        this.$bvModal.hide("modal-action-singular-with-cnss");
                                        
                                        if (this.isFiltre == true) {
                                            this.applyFilterBusinessSalaryPaymentAction(cnss)
    
                                        } else {
                                            this.showSalaireDetails(this.clickedDetailPackage, cnss);
                                        }
                                        this.applyGetSalaryPaymentHistoryAction();
                                        }
    
                                    
                                })
                                .catch((error) => {
                                    // this.lineItemDirectOrderId = null,
                                    // this.lineItemEmployeeId = null,
                                    console.log(error);
                                    this.isAdvancePaymentLoading = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Erreur",
                                            icon: "AlertTriangleIcon",
                                            text: error.response.data.message,
                                            variant: "danger",
                                        },
                                    }, {
                                        position: "top-center",
                                    });
                                    if (cnss == 0) {
                                        this.hideModalPaymentAdvanceMonthFormWithoutCnss()
                                    } else {
                                        this.hideModalPaymentAdvanceMonthForm()
                                    }
                                });
    
                        }
    
                    });
                }
            },
    
            hideModalPaymentFileModal() {
                this.$bvModal.hide("modal-new-payement-operation");
                this.isPaymentLoading = false;
                this.payement = {
                    month: "",
                    mobile_money_account: "",
                    is_in_ylomi_program: "",
                };
            },
    
            // hideModalPaymentAdvanceMonthForm() {
            //   this.lineItemDirectOrderId = null;
            //   this.lineItemEmployeeId = null;
            //   this.$bvModal.hide("modal-payment-advance-month");
            // },
    
            showAdvancePayementMonthModal(item) {
                this.lineItemDirectOrderId = item.direct_order_id;
                this.lineItemEmployeeId = item.employee_id;
                this.payload.month = item.month_salary;
                this.$bvModal.show("modal-payment-advance-month");
            },
    
            showEmployeeWalletDetails(walletId) {
                console.log(walletId);
                this.$router.push({
                    name: "admin-employees-wallet",
                    params: {
                        id: walletId
                    },
                });
            },
    
            showPayement() {
                this.$bvModal.show("modal-new-payement-operation");
            },
    
            clikedWithoutCnss(data, cnss) {
                console.log(data)
                this.paymentLink = `Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: \n *https://ylomi.net/direct/${data.package_id}/salary/payments?month=${data.month_salary}&year=${data.year}&cnss=${cnss}&montant=${data.total_amount_to_be_paid}&avance=${data.total_salary_advance_amount}&ylomi_fees=${data.total_ylomi_direct_fees}*`
                this.generate = []
                this.generate.push({
                    paymentLink: this.paymentLink
                })
    
            },
            clikedWithCnss(data, cnss) {
    
                this.paymentLink = `Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: \n *https://ylomi.net/direct/${data.package_id}/salary/payments?month=${data.month_salary}&year=${data.year}&cnss=${cnss}&montant=${data.total_amount_to_be_paid}&avance=${data.total_salary_advance_amount}&ylomi_fees=${data.total_ylomi_direct_fees}*`
                this.generate = []
                this.generate.push({
                    paymentLink: this.paymentLink
                })
    
            },
            clikedSigular(data) {
                console.log(data)
                if(this.isFiltre == true){
                    this.paymentLink = `Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} \n *https://ylomi.net/direct/${data.id}/payment/*`
                    this.generate = []
                    this.generate.push({
                        paymentLink: this.paymentLink
                    })
                }
                else {
                    this.paymentLink = `Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} \n *https://ylomi.net/direct/${data.id}/payment/*`
                    this.generate = []
                    this.generate.push({
                        paymentLink: this.paymentLink
                })
                }
                
            },
    
            copyPaymentLink() {
                navigator.clipboard.writeText(this.paymentLink)
                    .then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Lien copié avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    })
            },
    
            sendPaiementLinkToCustomer(data) {
                window.open(
                    `https://wa.me/${data.phone_number}?text=Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: *https://ylomi.net/direct/${data.package_id}/payments/*`
                );
            },
    
            sendSingularSalaryPaymentLinkWithoutCnss(data) {
                window.open(
                    `https://wa.me/${this.clickedDetailPhoneNumber}?text=Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} https://ylomi.net/direct/${data.package_id}/payment/${data.id}`
                );
            },
    
            sendSingularSalaryPaymentLink(data) {
                window.open(
                    `https://wa.me/${this.clickedDetailPhoneNumber}?text=Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} https://ylomi.net/direct/payment/${data.id}`
                );
            },
    
            // clickedDetail(data){
            //   this.customerPhone_number = data.customer_phone_number
            // },
    
            showActionTimesModal(item) {
                this.$bvModal.show("modal-action-times");
                this.currentOrder = item;
            },
    
            applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
                this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", true);
                this.getDirectOrdersAssignedToBusinessManagerAction(id)
                    .then(() => {
                        this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                    })
                    .catch(() => {
                        this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                    });
            },
    
            applyGetDirectOrderAction() {
                this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", true);
    
                this.getDirectOrdersAction()
                    .then(() => {
                        this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                    })
                    .catch(() => {
                        this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                    });
            },
    
            applyGetBusinessManagersListAction() {
                this.getBusinessManagersListAction().then((response) => {
                    response.data.forEach((item) => {
                        this.managerOptions.push({
                            label: item.full_name,
                            value: item.id
                        });
                    });
                    if (response.data.length === 0) {
                        this.businessManagerOptions.push({
                            value: "",
                            disabled: true,
                            text: "La liste des chargés d'affaire est vide",
                        });
                    } else {
                        response.data.forEach((element) => {
                            this.businessManagerOptions.push(element);
                        });
                    }
                });
            },
    
            applyOrderAssignment() {
                this.isOrderAssignmentLoading = true;
                console.log(this.orderToAssign.id);
                this.assignAnOrderToBusinessManagerAction({
                        orderId: this.orderToAssign.id,
                        payload: this.payload,
                    })
                    .then(async (response) => {
                        await this.getDirectOrdersAction();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: response.data.message,
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.refetchData();
                        this.isOrderAssignmentLoading = false;
                        this.$bvModal.hide("modal-order-assignment");
                        this.payload = {
                            charge_daffaire_id: "",
                        };
                        // this.$router.go()
                    })
                    .catch((error) => {
                        this.isOrderAssignmentLoading = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Erreur",
                                icon: "AlertTriangleIcon",
                                text: error,
                                variant: "danger",
                            },
                        }, {
                            position: "top-center",
                        });
                    });
            },
    
            sendContractByWhatsApp(data) {
                window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutation!
    Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`);
            },
    
            sendPlecementFeesPaymentLink(data) {
                window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutation!
    Merci de cliquer sur le lien suivant afin de payer les frais de placement pour votre demande de *${data.recurring_service.name}*. \n\n https://ylomi.net/direct/${data.id}/placement`);
            },
    
            resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
                if (status === -1) return "danger";
                if (status === 0 && placementCostPaid === false && searchEmployee === true) {
                    return "danger";
                }
                if (status === false) return "warning";
                if (status === true) return "success";
                if (status === 0) return "warning";
                if (status === 1) return "info";
                if (status === 2) return "warning";
                if (status === 3 || status === 4) return "success";
                return "primary";
            },
    
            resolveStatusText: (status) => {
                if (status === 0) return "Non payé";
                if (status === 1) return "Payé";
            },
    
            async fetchEmployees() {
                const array = [];
                const response = await axios.get("https://jsonplaceholder.typicode.com/todos/1");
                console.table(response);
                array.push(response.data);
                return array;
            },
        },
    };
    </script>
    
    <style>
    .vgt-select {
        background: transparent !important;
        border-color: #404656 !important;
    }
    </style>
    
    <style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    
    .invoice-filter-select {
        min-width: 190px;
    
        ::v-deep .vs__selected-options {
            flex-wrap: nowrap;
        }
    
        ::v-deep .vs__selected {
            width: 100px;
        }
    }
    </style>
    
    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-good-table.scss";
    </style>
    